import { getParent, Instance, IStateTreeNode, types } from "mobx-state-tree"
import { withEnvironment } from "./extensions/with-environment"
import { PitchActionType } from "../models/pitch"
import { withPitchStore } from "./pitch-store"
import { DateTime, StringEnum } from "../utils"
import { StudioPitchInfo } from "../models/studio-pitch-info"
import { RootStoreModel } from "./root-store"

export enum IntentActionType {
  Group = "Group",
  Playlist = "Playlist",
  Self = "Self",
  Assignment = "Assignment",
  PeerToPeer = "PeerToPeer",
}

export interface PitchIntentProps {
  isValid: boolean
  actionType?: PitchActionType
  actionEntityId?: string
  actionTargetId?: string
  scheduledUtc?: Date
  assignmentUserId?: string
}

export const PitchIntentStoreModel = types
  .model("PitchIntentStore")
  .props({
    intentAction: types.maybe(StringEnum(IntentActionType)),
    actionEntityId: types.maybe(types.string),
    actionTargetId: types.maybe(types.string),
    postToWall: types.maybe(types.boolean),
    scheduledUtc: types.maybe(DateTime),
    assignmentUserId: types.maybe(types.string),
    error: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withPitchStore)
  .actions((self) => ({
    reset() {
      self.actionEntityId = undefined
      self.actionTargetId = undefined
      self.postToWall = undefined
      self.error = undefined
      self.scheduledUtc = undefined
      self.assignmentUserId = undefined
    },
  }))
  .actions((self) => ({
    setError(error?: string) {
      self.error = error
    },
    setScheduledUtc(scheduledUtc?: Date) {
      self.scheduledUtc = scheduledUtc
    },
    setIntentAction(intentAction: IntentActionType) {
      if (intentAction !== self.intentAction) {
        self.reset()
        self.intentAction = intentAction
      }
    },
    setPostToWall(postToWall: boolean) {
      self.postToWall = postToWall
      self.actionTargetId = self.actionEntityId
    },
    setSelfDestination(entityId?: string) {
      self.intentAction = IntentActionType.Self
      self.actionEntityId = entityId
    },
    setGroupDestination(entityId: string) {
      self.intentAction = IntentActionType.Group
      self.actionEntityId = entityId
      self.actionTargetId = entityId
      self.scheduledUtc = undefined
    },
    setPlaylistDestination(playlistId: string, entityId: string) {
      self.intentAction = IntentActionType.Playlist
      self.actionEntityId = entityId
      self.actionTargetId = playlistId
      self.scheduledUtc = undefined
    },
    setAssignmentDestination(playlistId: string, entityId: string, assignmentUserId: string) {
      self.intentAction = IntentActionType.Assignment
      self.actionEntityId = entityId
      self.actionTargetId = playlistId
      self.scheduledUtc = undefined
      self.assignmentUserId = assignmentUserId
    },
    initializeFromStudioPitchInfo(studioPitchInfo: StudioPitchInfo, currentUserId?: string) {
      self.reset()

      switch (studioPitchInfo.actionType) {
        case PitchActionType.AddToPlaylist:
          self.intentAction = IntentActionType.Playlist
          self.actionTargetId = studioPitchInfo.actionTargetId
          self.actionEntityId = studioPitchInfo.actionEntityId
          self.scheduledUtc = studioPitchInfo.scheduledUtc || undefined
          if (studioPitchInfo.assignmentUserId) {
            self.intentAction = IntentActionType.Assignment
            self.assignmentUserId = studioPitchInfo.assignmentUserId
          }
          break
        case PitchActionType.PeerToPeer:
          self.intentAction = IntentActionType.PeerToPeer
          self.actionTargetId = studioPitchInfo.actionTargetId
          self.actionEntityId = studioPitchInfo.actionEntityId
          break
        case PitchActionType.PostToWall:
          self.intentAction = IntentActionType.Group
          self.actionEntityId = studioPitchInfo.actionEntityId
          self.actionTargetId = studioPitchInfo.actionEntityId
          self.postToWall = true
          break
        case PitchActionType.SetProfilePitch:
          self.intentAction = IntentActionType.Self
          self.actionEntityId = studioPitchInfo.actionEntityId
          break
        case PitchActionType.Publish:
          if (studioPitchInfo.actionEntityId === currentUserId) {
            self.intentAction = IntentActionType.Self
            self.actionEntityId = studioPitchInfo.actionEntityId
          } else if (studioPitchInfo.actionEntityId) {
            self.intentAction = IntentActionType.Group
            self.actionEntityId = studioPitchInfo.actionEntityId
          } else {
            self.intentAction = undefined
          }
          break
        default:
          self.intentAction = undefined
      }
    },
  }))
  .views((self) => ({
    get pitchIntentProps(): PitchIntentProps {
      let actionType = undefined as PitchActionType | undefined
      let actionEntityId = undefined as string | undefined
      let actionTargetId = undefined as string | undefined
      let hasRequiredProps = false
      if (self.intentAction === IntentActionType.Group) {
        if (self.postToWall) {
          actionType = PitchActionType.PostToWall
          actionEntityId = self.actionEntityId
          actionTargetId = self.actionTargetId
          hasRequiredProps =
            Boolean(actionType) && Boolean(actionEntityId) && Boolean(actionTargetId)
        } else {
          actionType = PitchActionType.Publish
          actionEntityId = self.actionEntityId
          hasRequiredProps = Boolean(actionType) && Boolean(actionEntityId)
        }
      } else if (
        self.intentAction === IntentActionType.Playlist ||
        self.intentAction === IntentActionType.Assignment
      ) {
        actionType = PitchActionType.AddToPlaylist
        actionEntityId = self.actionEntityId
        actionTargetId = self.actionTargetId
        hasRequiredProps = Boolean(actionType) && Boolean(actionEntityId) && Boolean(actionTargetId)
        if (self.intentAction === IntentActionType.Assignment) {
          hasRequiredProps = hasRequiredProps && Boolean(self.assignmentUserId)
        }
      } else if (self.intentAction === IntentActionType.Self) {
        const publishToProfile = true // Always publish as profile video now
        if (publishToProfile) {
          actionType = PitchActionType.SetProfilePitch
          actionEntityId = self.actionEntityId
          hasRequiredProps = Boolean(actionType) && Boolean(actionEntityId)
        } else {
          actionType = PitchActionType.Publish
          actionEntityId = self.actionEntityId
          hasRequiredProps = Boolean(actionType) && Boolean(actionEntityId)
        }
      } else if (self.intentAction === IntentActionType.PeerToPeer) {
        actionType = PitchActionType.PeerToPeer
        actionEntityId = self.actionEntityId
        actionTargetId = self.actionTargetId
        hasRequiredProps = Boolean(actionType) && Boolean(actionEntityId) && Boolean(actionTargetId)
      }

      return {
        isValid: hasRequiredProps && !self.error,
        actionType,
        actionEntityId,
        actionTargetId,
        scheduledUtc: self.scheduledUtc,
        assignmentUserId: self.assignmentUserId,
      }
    },
  }))

export type PitchIntentStore = Instance<typeof PitchIntentStoreModel>
export const withPitchIntentStore = (self: IStateTreeNode) => ({
  views: {
    get pitchIntentStore(): PitchIntentStore {
      return getParent<Instance<typeof RootStoreModel>>(self).pitchIntentStore
    },
  },
})
